import React from 'react';
import VideoPlayer from '../../components/VideoPlayer';
import { useRenderContext } from '../../pages/home/context';

const VideoPlayerComponent = (props) => {
  const { isEshops, shopType } = useRenderContext();
  return <VideoPlayer {...props} isEshops={isEshops} eshopType={shopType} />;
};

export default VideoPlayerComponent;
